<template>
  <Print :open="open" :styles="styles" :title="form.title" v-on:close="close">
    <div class="page">
      <div id="message_headers">
        <dl v-if="form.client">
          <dt>{{ $t("messagePrint.from") }}</dt>
          <dd>{{ form.client.name }}</dd>
        </dl>

        <dl>
          <dt>{{ $t("task.printView.title") }}</dt>
          <dd>
            <strong>{{ form.title }}</strong>
          </dd>
        </dl>
      </div>
      <slot></slot>
    </div>
  </Print>
</template>

<script setup lang="ts">
import Print from "@/components/Print.vue"

const emit = defineEmits(["update:open"])
const { form, open } = defineProps<{
  form: Object
  open: Boolean
}>()

const styles = ["/print.css"]

const close = () => {
  emit("update:open", false)
}
</script>
