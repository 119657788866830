<template>
  <spinner v-if="loading" centered />
  <div v-else-if="resource" class="app-panel">
    <div id="publishing-detail-container" class="app-panel-body scrollable">
      <nav class="py-3 px-4 mb-2 d-flex align-items-center justify-content-between">
        <div>
          <a href="#" @click.prevent="close" class="fs-18 mr-3 ml-1" style="position: relative; top: 2px">
            <fa-icon name="times" />
          </a>
        </div>
        <div>
          <dropdown>
            <template v-slot:title>
              <span>{{ $t("panes.taskDetail.followup") }}</span>
              <fa-icon name="angle-down" class="ml-1" />
            </template>
            <dropdown-section>
              <dropdown-item :title="$t('panes.taskDetail.followupNote')" @click="followup('note')" />
              <dropdown-item :title="$t('panes.taskDetail.followupTask')" @click="followup('todo')" />
              <dropdown-item :title="$t('panes.taskDetail.followupEvent')" @click="followup('event')" />
              <dropdown-item :title="$t('panes.taskDetail.followupMail')" @click="followup('mail')" />
              <dropdown-item :title="$t('panes.taskDetail.followupLetter')" @click="followup('letter')" />
              <dropdown-item :title="$t('panes.taskDetail.cancellation')" @click="followup('reason')" />
            </dropdown-section>
          </dropdown>
          <dropdown>
            <dropdown-section>
              <dropdown-item new-tab title="Druckansicht öffnen" @click="printPageVisible = true" />
              <dropdown-item @click="deleteTask" title="Löschen" class="text-red-600" />
            </dropdown-section>
            <dropdown-section>
              <p @click.stop class="text-gray-600 text-xs px-3 py-2">Kennung: {{ resource.id }}</p>
            </dropdown-section>
          </dropdown>
        </div>
      </nav>
      <main class="flex">
        <div class="mr-auto my-1 ml-6 invisible" />
        <div class="py-0 pl-4 pr-6 w-11/12">
          <header class="form-group">
            <h3 class="notes-title">
              {{ $t("admin.forms.entry") + ` (${resource.id})` }}
            </h3>
          </header>

          <pre v-if="resource.entryData">
            <form-row v-for="[name, value] in Object.entries(resource.entryData)" :title="name.startsWith('cf_') ? customFieldNames[name.slice(3)].name : $t(`clients.formFields.${name}`)">
              <span>{{ formatValue(value, name) }}</span>
            </form-row>
          </pre>
        </div>
      </main>
      <comments-app :resource-id="id" v-model:watchers="resource.watcherBrokerIds" resource-type="Task">
        <div class="activityLog-item">
          <p>{{ $t("panes.taskDetail.formSend", { contact: resource.client.name }) }}</p>
          <nice-tooltip
            class="activityLog-date"
            :content="$customFilters.datetime(resource.createdAt)"
            placement="left"
          >
            <span>
              {{ $customFilters.calendar(resource.createdAt) }}
            </span>
          </nice-tooltip>
        </div>
      </comments-app>
    </div>
    <custom-form-print v-model:open="printPageVisible" :form="printTemplate">
      <pre v-if="resource.entryData" class="mt-5">
        <table class="table table-bordered">
          <tbody>
            <tr v-for="[name, value] in Object.entries(resource.entryData)">
              <td style="width: 175px">
                {{ name.startsWith("cf_") ? customFieldNames[name.slice(3)].name : $t(`clients.formFields.${name}`) }}
              </td>
              <td>
                <strong>{{ formatValue(value, name) }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </pre>
    </custom-form-print>
  </div>
</template>

<script setup lang="ts">
import CustomFormPrint from "@/views/print/CustomForm.vue"
import CommentsApp from "@/components/Comments.vue"
import { ref, onMounted, computed, getCurrentInstance } from "vue"
import useCore from "@/plugins/use-core"
import { useI18n } from "vue-i18n"
const { graphql, db, axios } = useCore()
const { t } = useI18n()
const confirm = getCurrentInstance()?.proxy?.$confirm

const emit = defineEmits(["close", "params"])
const { id } = defineProps<{
  id: Number
}>()

const loading = ref(true)
const printPageVisible = ref(false)
const resource = ref(null) as any

const fetchData = async () => {
  try {
    loading.value = true
    const { formEntry: data } = await graphql(`
      query formEntry {
        formEntry(id: ${id}) {
          id
          client { id name }
          entryData
          createdAt
        }
      }
    `)

    resource.value = data
    loading.value = false
  } catch (e) {
    console.error(e)
    close()
  }
}

const deleteTask = () => {
  confirm("Soll die Aktivität wirklich gelöscht werden?", "Aktivität löschen")
    .then(_ => {
      // axios.delete(`/services/text_messages/${id}`)
      emit("close")
      App.flashy("Aktivität wurde erfolgreich gelöscht")
    })
    .catch(_ => {})
}

const close = () => {
  emit("close")
}

const followup = type => {
  emit("params", {
    type: type,
    mode: "edit",
    params: {
      source: {
        title: t("admin.forms.entry") + ` (${id})`,
        clientIds: [resource.value.client.id],
        taskId: type == "mail" || type == "letter" ? id : undefined,
      },
      followupTaskId: id,
    },
  })
}

const formatValue = (value, name) => {
  if (name === "salutation") return salutationOptions.value[value]
  if (Array.isArray(value)) return value.join(", ")
  if (name.startsWith("cf_") && customFieldNames.value[name.slice(3)].options?.length)
    return customFieldNames.value[name.slice(3)].options.find(option => option.id == value)?.name
  return value
}

const printTemplate = computed(() => {
  return { ...resource.value, title: t("admin.forms.entry") + ` (${id})` }
})

const customFieldNames = computed(() => {
  const fields = db.shopData.customFieldGroupsForClients.flatMap(group => group.customFields)
  return fields.reduce(
    (obj, item) => Object.assign(obj, { [item.name]: { name: item.prettyName, options: item.customOptions } }),
    {}
  )
})

const salutationOptions = computed(() => {
  const defaultSalutations = [
    { id: "mr", name: "nameSalutationMr" },
    { id: "ms", name: "nameSalutationMs" },
    { id: "mrms", name: "nameSalutationMrms" },
  ]
  return defaultSalutations
    .map(o => ({ ...o, name: t(`detailView.${o.name}`) }))
    .concat(db.get("salutations").map(o => ({ id: o.internalName, name: o.name })))
    .reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {})
})

onMounted(() => {
  fetchData()
})
</script>
