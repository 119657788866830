const getCustomFields = groups => groups.map(cg => cg.customFields).flat()

const brokerVariables = (name = "nutzer", { customFieldGroupsForBrokers }) => [
  ["salutation", `{{ ${name}.anrede }}`],
  ["title", `{{ ${name}.titel }}`],
  ["firstname", `{{ ${name}.vorname }}`],
  ["lastname", `{{ ${name}.nachname }}`],
  ["name", `{{ ${name}.name }}`],
  ["email", `{{ ${name}.email }}`],
  ["publicEmail", `{{ ${name}.oeffentliche_email }}`],
  ["mobile", `{{ ${name}.mobil }}`],
  ["phone", `{{ ${name}.telefon }}`],
  ["acronym", `{{ ${name}.kuerzel }}`],
  ["position", `{{ ${name}.position }}`],
  ...getCustomFields(customFieldGroupsForBrokers).map(cf => [
    cf.prettyName,
    `{{ ${name}.custom.${cf.name} }}`,
    "custom",
  ]),
]

let CLIENT_VARIABLES = [
  ["emailSalutution", "{{ adressierte_anrede }}"],
  ["emailSalututionExtra", "{{ anrede }}"],
  ["name", "{{ kontakt.name }}"],
  ["salutation2", "{{ kontakt.anrede }}"],
  ["title", "{{ kontakt.titel }}"],
  ["firstname", "{{ kontakt.vorname }}"],
  ["lastname", "{{ kontakt.nachname }}"],
  ["email", "{{ kontakt.email }}"],
  ["source", "{{ kontakt.quelle }}"],
  ["address", "{{ kontakt.adresse }}"],
  ["phone", "{{ kontakt.telefon }}"],
  ["company", "{{ kontakt.firma }}"],
  ["rentDate", "{{ kontakt.mietbeginn }}"],
  ["commissionSplits", "{{ kontakt.provisionen }}"],
  ["commissionSplitsFrom", `{{ kontakt.provisionen | zeitraum_ab: "erstellt_am", "date" }}`],
]

let PROJECT_VARIABLES = [
  ["title", "{{ projekt.titel }}"],
  ["address", "{{ projekt.adresse }}"],
  ["zipcode", "{{ projekt.plz }}"],
  ["city", "{{ projekt.stadt }}"],
  ["location", "{{ projekt.geolage }}"],
  ["frontImage", "{{ projekt.titelbild }}"],
  ["expose", "{{ projekt.dokument_exposee_link }}"],
  ["projectMassEventBtn", "{{ projekt.massentermin.massentermin_button }}"],
  ["projectMassEventList", "{{ projekt.massentermin_liste }}"],
  ["subHeadline", "{{ projekt.unterueberschrift }}"],
  ["projectDescriptionNote", "{{ projekt.projektbeschreibung }}"],
  ["locationNote", "{{ projekt.lage }}"],
  ["furnishingNote", "{{ projekt.ausstattung }}"],
  ["constructionYear", "{{ projekt.baujahr }}"],
  ["status", "{{ projekt.status }}"],
  ["projectId", "{{ projekt.projektnummer }}"],
]

let PROPERTY_VARIABLES = [
  ["title", "{{ einheit.titel }}"],
  ["id", "{{ einheit.einheitennummer }}"],
  ["address", "{{ einheit.adresse }}"],
  ["streetFull", "{{ einheit.strasse }}"],
  ["street", "{{ einheit.nur_strasse }}"],
  ["zipcode", "{{ einheit.plz }}"],
  ["city", "{{ einheit.stadt }}"],
  ["location", "{{ einheit.geolage }}"],
  ["region", "{{ einheit.region }}"],
  ["country", "{{ einheit.land }}"],
  ["frontImage", "{{ einheit.titelbild }}"],
  ["price", "{{ einheit.preis }}"],
  ["baserent", "{{ einheit.kaltmiete }}"],
  ["rent", "{{ einheit.warmmiete }}"],
  ["heatingcost", "{{ einheit.heizkosten }}"],
  ["sidecost", "{{ einheit.nebenkosten }}"],
  ["area", "{{ einheit.flaeche }}"],
  ["rooms", "{{ einheit.zimmeranzahl }}"],
  ["type", "{{ einheit.typ }}"],
  ["category", "{{ einheit.art }}"],
  ["rented", "{{ einheit.vermietet }}"],
  ["rentalIncome", "{{ einheit.mieteinnahmen }}"],
  ["subsidy", "{{ einheit.hausgeld }}"],
  ["courtage", "{{ einheit.provision }}"],
  ["floor", "{{ einheit.etage }}"],
  ["deposit", "{{ einheit.kaution }}"],
  ["availability", "{{ einheit.einzugstermin }}"],
  ["garagetype", "{{ einheit.garagentyp }}"],
  ["valuationPriceFrom", "{{ einheit.bewertung_preis_von }}"],
  ["valuationPriceTo", "{{ einheit.bewertung_preis_bis }}"],
  ["propertyMassEventBtn", "{{ einheit.massentermin.massentermin_button }}"],
  ["propertyMassEventList", "{{ einheit.massentermin_liste }}"],
  ["commissionSplits", "{{ einheit.provisionen }}"],
  ["serviceChargeNet", "{{ einheit.betriebskosten_netto }}"],
  ["minRentalTime", "{{ einheit.mindestmietdauer }}"],
  ["maxRentalTime", "{{ einheit.maximale_mietdauer }}"],
  ["previousPrice", "{{ einheit.vorheriger_preis }}"],
]

let LANDING_PAGE_VARIABLES = [
  ["exposee", "{{ exposee_button }}"],
  ["property", "{{ einheit_button }}"],
  ["propertyStats", "{{ einheit_statistik_button }}"],
  ["propertyValuation", "{{ einheit_bewertung_button }}"],
  ["project", "{{ projekt_info_button }}"],
  ["projectOffer", "{{ projekt_angebot_button }}"],
  ["permission", "{{ kontakt_link }}"],
  ["permissionCancel", "{{ abbestellen_link }}"],
  ["completeAddress", "{{ kontakt_profil_button }}"],
  ["query", "{{ kontakt_suchkriterien_button }}"],
  ["propertyReport", "{{ einheit_tracking_button }}"],
  ["projectReport", "{{ projekt_report_button }}"],
  ["attachmentLink", "{{ dokument_link }}"],
  ["attachmentBtn", "{{ dokument_button }}"],
  ["eventCancelation", "{{ termin_absage_button }}"],
  ["eventConfirmation", "{{ termin_zusage_button }}"],
  ["propertyFeedback", "{{ einheit_feedback_button }}"],
  ["emailVerification", "{{ email_verifizieren_link }}"],
  ["pdfExposee", "{{ einheit_exposee_button }}"],
  ["projectExposee", "{{ projekt_exposee_button }}"],
  ["brokerage", "{{ maklerauftrag_button }}"],
  ["mailingCancel", '{{ immobilienmailings_abbestellen_link | linktext: "Abbestellen" }}'],
  ["newsletterCancel", '{{ newsletter_abbestellen_link | linktext: "Abbestellen" }}'],
]

export const getVariables = (db, { folders, bookingCalendars, uploadableFolders, forms }) => {
  let landingPageVariables = LANDING_PAGE_VARIABLES
  if (!db.activeFeatures.includes("ph_valuation") && !db.activeFeatures.includes("sn_valuation"))
    landingPageVariables = landingPageVariables.filter(v => v[0] !== "propertyValuation")
  if (!db.activeFeatures.includes("property_statistics"))
    landingPageVariables = landingPageVariables.filter(v => v[0] !== "propertyStats")
  landingPageVariables = landingPageVariables.concat(
    folders.map(f => [`folder`, `{{ dokument_ordner_${f.id}_button }}`, f.name])
  )
  if (uploadableFolders) {
    landingPageVariables = landingPageVariables.concat(
      uploadableFolders.map(f => [`upload`, `{{ dokument_upload_${f.id}_button }}`, f.name])
    )
  }
  landingPageVariables = landingPageVariables.concat(
    bookingCalendars.map(bc => [`booking`, `{{ termin_tool_button_${bc.id} }}`, bc.name])
  )
  if (forms.length) {
    landingPageVariables = landingPageVariables.concat(
      forms.map(f => [`form`, `{{ formular_${f.id}_button }}`, f.title])
    )
  }

  const clientVariables = CLIENT_VARIABLES.concat(
    getCustomFields(db.customFieldGroupsForClients).map(cf => [
      cf.prettyName,
      `{{ kontakt.custom.${cf.name} }}`,
      "custom",
    ])
  )
  const propertyVariables = PROPERTY_VARIABLES.concat(
    getCustomFields(db.customFieldGroupsForProperties).map(cf => [
      cf.prettyName,
      `{{ einheit.custom.${cf.name} }}`,
      "custom",
    ])
  )
  const projectVariables = PROJECT_VARIABLES.concat(
    getCustomFields(db.customFieldGroupsForProjects).map(cf => [
      cf.prettyName,
      `{{ projekt.custom.${cf.name} }}`,
      "custom",
    ])
  )
  const docusignVariables = db.docusignTemplates?.map(f => [`${f.name}`, `{{ docusign_${f.id}_link }}`, "custom"])
  return [
    ["lp", landingPageVariables],
    docusignVariables ? ["docusign", docusignVariables] : undefined,
    [
      "methods",
      [
        ["btn", `{{ "https://www.meine-url.de/..." | button: "Titel des Buttons", "${db.color || "#0f55eb"}" }}`],
        ["condition", "{% if ... %}<br>Bedingung ist wahr<br>{% endif %}"],
        ["htmlList", "{{ kontakt.custom.eigenes_multiselect_feld | html_liste }}"],
      ],
    ],
    ["message", [["attachmentList", "{{ anhaenge | html_liste }}"]]],
    ["contact", clientVariables],
    ["property", propertyVariables],
    ["project", projectVariables],
    ["sender", brokerVariables("nutzer", db)],
    ["currentUser", brokerVariables("sender", db)],
    ["propertyUser", brokerVariables("einheit.betreuer", db)],
    ["contactUser", brokerVariables("betreuer", db)],
    ["contactCoUser", brokerVariables("co_betreuer", db)],
    ["projectUser", brokerVariables("projekt.betreuer", db)],
    [
      "propertyOwner",
      [
        ["name", "{{ einheit.eigentuemer.name }}"],
        ["address", "{{ einheit.eigentuemer.adresse }}"],
      ],
    ],
    [
      "projectOwner",
      [
        ["name", "{{ projekt.eigentuemer.name }}"],
        ["address", "{{ projekt.eigentuemer.adresse }}"],
      ],
    ],
    [
      "event",
      [
        ["title", "{{ termin.titel }}"],
        ["place", "{{ termin.ort }}"],
        ["startMonday", "{{ termin.start.tag }}"],
        ["startDate", "{{ termin.start.datum }}"],
        ["startTime", "{{ termin.start.zeit }}"],
        ["endFriday", "{{ termin.end.tag }}"],
        ["endDate", "{{ termin.end.datum }}"],
        ["endTime", "{{ termin.end.zeit }}"],
        ["timezone", "{{ termin.end.zeitzone }}"],
        ["massEventBtn", "{{ termin.massentermin_button }}"],
      ],
    ],
    [
      "tenant",
      [
        ["name", "{{ mandant.name }}"],
        ["street", "{{ mandant.strasse }}"],
        ["zipcode", "{{ mandant.plz }}"],
        ["place", "{{ mandant.ort }}"],
        ["email", "{{ mandant.email }}"],
        ["phone", "{{ mandant.telefon }}"],
        ["fax", "{{ mandant.fax }}"],
        ["ceo", "{{ mandant.ceo }}"],
        ["directorsBoard", "{{ mandant.aufsichtsrat }}"],
        ["registryCourt", "{{ mandant.registergericht }}"],
        ["registryNum", "{{ mandant.registernummer }}"],
        ["salesTaxId", "{{ mandant.steuer_id }}"],
      ],
    ],
    [
      "psAcc",
      [
        ["name", "{{ konto.name }}"],
        ["street", "{{ konto.strasse }}"],
        ["zipcode", "{{ konto.plz }}"],
        ["place", "{{ konto.ort }}"],
        ["phone", "{{ konto.telefon }}"],
        ["fax", "{{ konto.fax }}"],
        ["email", "{{ konto.email }}"],
        ["website", "{{ konto.webseite }}"],
        ["ceo", "{{ konto.ceo }}"],
        ["directorsBoard", "{{ konto.aufsichtsrat }}"],
        ["registryCourt", "{{ konto.registergericht }}"],
        ["registryNum", "{{ konto.registernummer }}"],
        ["salesTaxId", "{{ konto.steuer_id }}"],
        ["bank", "{{ konto.bank }}"],
        ["iban", "{{ konto.iban }}"],
        ["bic", "{{ konto.bic }}"],
      ],
    ],
  ].filter(Boolean)
}
