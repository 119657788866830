<template>
  <div class="flex flex-col items-center space-y-4 p-4 bg-white rounded-lg shadow-md">
    <div class="flex items-start space-x-2">
      <input type="checkbox" id="consent-checkbox" v-model="hasConsented" class="mt-1" />
      <label for="consent-checkbox" class="text-md text-gray-700">
        I accept the
        <a href="#" class="text-blue-600 hover:text-blue-800 underline">terms and conditions</a>
        agreement
        <label class="text-sm text-gray-500">(requires reload)</label>
      </label>
    </div>

    <button
      @click="handleStartChat"
      :disabled="!hasConsented"
      class="px-4 py-2 bg-blue-600 text-lg text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Start Chat
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue"

const hasConsented = ref(false)
const showError = ref(false)

const emit = defineEmits<{
  (event: "consent"): void
}>()

const handleStartChat = () => {
  if (hasConsented.value) {
    showError.value = false
    emit("consent")
  } else {
    showError.value = true
  }
}
</script>
