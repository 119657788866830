import { marked } from "marked"
import DOMPurify from "dompurify"
import { purifyConfig } from "./constants"

marked.setOptions({
  breaks: true,
  gfm: true,
  headerIds: false,
  mangle: false,
})

const markdownToHtml = (content: string) => {
  const htmlContent = marked(content)
  return DOMPurify.sanitize(htmlContent, purifyConfig)
}

export { markdownToHtml }
