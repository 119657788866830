<template>
  <div ref="chatWindowParentRef" class="inset-0 fixed z-[1000] pointer-events-none">
    <Transition
      enter-active-class="transition duration-250 ease-out"
      leave-active-class="transition duration-250 ease-out"
      enter-from-class="opacity-0 scale-90"
      leave-to-class="opacity-0 scale-90"
    >
      <button
        :class="`w-12 h-12 rounded-full border shadow-md absolute bottom-5 right-5 z-50 pointer-events-auto`"
        :style="{ background: '#15ca6e' }"
        @click="openChat"
        v-if="isChatMinimized"
      >
        <strong class="text-2xl text-white">
          <fa-icon name="magic" class="fa-fw mr-1 transform scaleX(-1)" />
        </strong>
      </button>
    </Transition>

    <Transition
      enter-active-class="transition duration-300 ease-out"
      leave-active-class="transition duration-200 ease-in"
      enter-from-class="opacity-0 translate-y-5"
      leave-to-class="opacity-0 translate-y-5"
    >
      <div
        v-if="isChatOpen"
        class="absolute inset-0 bg-black/20 pointer-events-auto"
        :class="{ 'w-0 h-0': isChatMinimized }"
      >
        <div
          ref="chatWindowInnerRef"
          :class="{
            'absolute bottom-5 right-5': true,
            'w-4/5 h-[90%]': hasConsented,
            'w-0 h-0': isChatMinimized,
          }"
          @click.stop
        >
          <ConsentBanner v-if="!hasConsented" @consent="onConsent" />
          <Assistant
            v-else
            :is-chat-minimized="isChatMinimized"
            @minimizeChat="isChatMinimized = !isChatMinimized"
            @endChat="endChat"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, nextTick } from "vue"
import useCore from "@/plugins/use-core"
import Assistant from "@/components/AIChat/Assistant/Assistant.vue"
import ConsentBanner from "@/components/AIChat/ConsentBanner.vue"

defineProps({ unreadMessages: { type: Number, default: 0 } })

const { db } = useCore()

const hasConsented = ref(db.broker.propstackAssistantConsent)
const isChatOpen = ref(false)
const isChatMinimized = ref(true)
const chatWindowParentRef = ref<HTMLDivElement | null>(null)
const chatWindowInnerRef = ref<HTMLDivElement | null>(null)

const openChat = () => {
  isChatOpen.value = true
  isChatMinimized.value = false
}
const closeChat = () => {
  isChatMinimized.value = true
  if (!hasConsented.value) {
    isChatOpen.value = false
  }
}
const endChat = () => {
  isChatMinimized.value = true
  isChatOpen.value = false
}

const onConsent = () => {
  // clear cache (otherwise old cache persists non-consented state)
  db.reset()

  const params = new URLSearchParams(window.location.search)
  params.set("consent", "true")
  const newUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`
  window.location.href = newUrl
}

const initializeChat = () => {
  const params = new URLSearchParams(window.location.search)
  if (params.has("consent")) {
    hasConsented.value = true
    isChatOpen.value = true
    isChatMinimized.value = false
    params.delete("consent")
    const newUrl = window.location.pathname + (params.toString() ? `?${params.toString()}` : "")
    window.history.replaceState({}, "", newUrl)
  }
}

const handleClickOutside = (event: MouseEvent) => {
  if (isChatOpen.value && !isChatMinimized.value && !chatWindowInnerRef.value?.contains(event.target as Node)) {
    closeChat()
  }
}

onMounted(() => {
  initializeChat()
  document.addEventListener("mousedown", handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener("mousedown", handleClickOutside)
})
</script>
