import { PusherAPI } from "@/plugins/realtime-plugin"
import { ref, getCurrentInstance } from "vue"

export function useAssistantSocket() {
  const pusher = ref<PusherAPI | undefined>(getCurrentInstance()?.proxy?.$pusher)
  const chatExtId = ref<string | null>(null)
  const error = ref<string | null>(null)
  const onMessage = (data: any, callback: (data: any) => void) => {
    if (data.body) {
      const messageContent = data.body
      if (messageContent.includes("PropstackError: ")) {
        error.value = messageContent
      } else {
        error.value = ""
        callback(data)
      }
    }
  }

  const connect = (chatExternalId: string, onMessageCallback: (data: any) => void) => {
    if (!pusher.value) {
      return console.error("Pusher is not initialized")
    }
    chatExtId.value = chatExternalId
    pusher.value.on(`propstack_assistant_chat@${chatExtId.value}`, data => onMessage(data, onMessageCallback))
  }

  const disconnect = () => {
    if (!pusher.value) {
      return console.error("Pusher is not initialized")
    }
    pusher.value.off(`propstack_assistant_chat@${chatExtId.value}`)
  }

  return { connect, disconnect }
}
