import { onMounted, ref } from "vue"
import useCore from "@/plugins/use-core"
import { Message } from "./types"
import { itemsPerPage } from "./constants"

export function useAssistantMessages() {
  const { axios, api } = useCore()
  const messages = ref<Message[]>([])
  const isLoading = ref(false)
  const currentPage = ref(0)
  const hasMore = ref(true)
  const externalId = ref<string>("")

  const getExternalId = async (message: string) => {
    const response = await api.mutation("createAssistantChat", { content: message }, "externalId")
    externalId.value = response.externalId
  }
  const fetchMessages = async (page = 1) => {
    try {
      isLoading.value = true

      const {
        data: { data: newMessages },
      } = await axios.get("/api/v1/assistant_messages", {
        params: {
          per: itemsPerPage,
          page,
          order: "desc",
        },
      })

      messages.value = (newMessages || []).reverse().concat(messages.value || [])
      hasMore.value = newMessages.length === itemsPerPage
      currentPage.value = page
    } catch (error) {
      console.error("Failed to fetch messages:", error)
    } finally {
      isLoading.value = false
    }
  }

  const fetchMore = () => {
    if (!isLoading.value && hasMore.value) {
      fetchMessages(currentPage.value + 1)
    }
  }

  const addMessageToChat = (message: Message) => {
    if (!message.role) throw new Error("Message sender's role is required")

    messages.value.push({
      ...message,
      id: message.id || Date.now(),
      createdAt: message.createdAt || new Date().toISOString(),
    })
  }

  const sendMessage = async (message: string) => {
    await api.mutation("createAssistantMessage", { content: message }).catch(console.error)
  }

  const clearContext = async (): Promise<void> => {
    isLoading.value = true
    return await api
      .mutation("cleanAssistantContext")
      .then(() => {
        messages.value = []
        hasMore.value = false
        currentPage.value = 1
        return Promise.resolve()
      })
      .catch(error => {
        console.error("Failed to clear context:", error)
        return Promise.reject(error)
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  onMounted(() => {
    getExternalId("")
  })

  return {
    externalId,
    messages,
    isLoading,
    hasMore,
    currentPage,
    addMessageToChat,
    fetchMore,
    clearContext,
    sendMessage,
  }
}
